'use stict';

var options = {
    
    offset: 960
}

var options2 = {
  
    offset: 67
}

var sidebar = new Headhesive('.menu-catalog', options);

var header = new Headhesive('.cart', options2);
 
